jQuery(function ($) {

	// Enable FitVids on the content area
	// $('.site-inner').fitVids();

	// Remove Genesis header aside
	$('.header-widget-area').remove();

	$('.menu-primary .sub-menu').hover(function () {
		$(this).parent('.menu-item-has-children').addClass('nav-hover');
	}, function () {
		$(this).parent('.menu-item-has-children').removeClass('nav-hover');
	});

	// Set up mobile menu
	var $menu = $('.nav-primary');
	$menu.before('<div class="mobile-menu">Menu</div>');
	$('body').append('<div class="mobile-overlay"</div>')
	$('.mobile-menu').on('click', function () {
		$('body').addClass('mobile-open mobile-opened');
	});

	$menu.prepend('<div class="menu-close">Menu<span></span></div>');
	$('.menu-close, .mobile-overlay').on('click', function () {
		$('body').removeClass('mobile-open');
	});
});