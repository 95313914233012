jQuery(function ($) {
  // Hover for images in content (magnific-popup)
  $('.entry [class^="wp-image-"]').each(function() {
    var img = $(this);

    //If they don't link the image, link it to itself.
    if (!img.parent('a').length) {
      $(img).wrap('<a href="' + img.attr('src') + '" class="wp-img-link"></a>');
    } else {
      img.parent('a').addClass('wp-img-link');
    }
  });
  $('.wp-img-link').magnificPopup({
    type: 'image',
    mainClass: 'mfp-with-zoom', // this class is for CSS animation below

    zoom: {
      enabled: true, // By default it's false, so don't forget to enable it

      duration: 300, // duration of the effect, in milliseconds
      easing: 'ease-in-out', // CSS transition easing function

      // The "opener" function should return the element from which popup will be zoomed in
      // and to which pop-up will be scaled down
      // By default it looks for an image tag:
      opener: function(openerElement) {
        // openerElement is the element on which popup was initialized, in this case its <a> tag
        // you don't need to add "opener" option if this code matches your needs, it's default one.
        return openerElement.is('img') ? openerElement : openerElement.find('img');
      }
    }
  });
});